import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavButton = _resolveComponent("NavButton")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_NavButton, {
      label: "About",
      link: "/",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Research",
      link: "/research",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Teaching",
      link: "/teaching",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Notes",
      link: "/notes",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Seminar",
      link: "https://locallytrivialseminar.github.io",
      "use-a": "",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "CV",
      link: "https://sachagoldman.github.io/CV/CV.pdf",
      "use-a": "",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"])
  ]))
}